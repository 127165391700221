<template>
    <div>
        <navbar/>
        <v-row>
        <v-col cols="12" sm="12">
            <div class="img" title="Système MiceTracking">
                <h1 style="z-index: 3;" class="big-title">{{ $t('page-home.healthWellbeingTracking') }}</h1>
                <p class="animation-group" style="top: 71.6%; left: 25.5%;">{{ $t('page-home.bloodPressure') }}</p>
                <p class="animation-group" style="top: 50.6%; left: 28.5%;">{{ $t('page-home.implant') }}</p>
                <p class="animation-group" style="top: 40.6%; left: 41.5%;">{{ $t('page-home.matrix') }}</p>
                <p class="animation-group" style="top: 50.6%; left: 66.5%;">{{ $t('page-home.connectedCage') }}</p>
                <p class="animation-group" style="top: 49.6%; left: 7.8%;">{{ $t('page-home.oxygen') }}</p>
                <p class="animation-group" style="top: 69.7%; left: 11.5%;">{{ $t('page-home.accelerometer') }}</p>
                <p class="animation-group" style="top: 14.6%; left: 13.9%;">{{ $t('page-home.temperature') }}</p>
                <p class="animation-group" style="top: 14.6%; left: 22.4%;">{{ $t('page-home.heartRate') }}</p>
                <p class="animation-group" style="top: 14%; left: 79%;">{{ $t('page-home.buzzer') }}</p>
                <p class="animation-group" style="top: 75.6%; left: 77.5%;">{{ $t('page-home.ammonia') }}</p>
                <p class="animation-group" style="top: 51.9%; left: 91.2%;">{{ $t('page-home.airJet') }}</p>
            </div>
        </v-col>
    </v-row>
    <v-row style="margin-top: 50px;">
        <v-col cols="12" sm="1" lg="1"></v-col>
        <v-col cols="12" sm="10" lg="5">
                <div class="paragraph">
                    <p class="text-classi">
                        {{ $t('page-home.trackingTechnologyDescriptionPart1')}}
                    </p>
                    <p class="text-classi">
                        {{ $t('page-home.trackingTechnologyDescriptionPart2')}}
                    </p>
                    <p class="text-classi">
                        {{ $t('page-home.trackingTechnologyDescriptionPart3')}}
                    </p>
                    <p class="text-classi">
                        {{ $t('page-home.trackingTechnologyDescriptionPart4')}}
                    </p>
                    <p class="text-classi">
                        {{ $t('page-home.trackingTechnologyDescriptionPart5')}}
                    </p>
                    <p class="text-classi">
                        {{ $t('page-home.trackingTechnologyDescriptionPart6')}}
                    </p>
                </div>
        </v-col>
        <v-col cols="12" sm="1" lg="1"></v-col>
            <v-col cols="12" xs="0" sm="1" lg="1"></v-col>
            <v-col cols="12" xs="12" sm="10" lg="4">
                <v-row class="row-between">
                    <div class="photo-1">
                        <img src="../images/souris-main.jpg" alt="souris dans une main" class="dezoom-effect-2">
                    </div>
                    <div style="width: 45%">
                        <div class="photo-3">
                            <img src="../images/implant-puce-électronique.jpg" alt="implant électronique" class="dezoom-effect">
                        </div>
                        <div class="photo-4">
                            <img src="../images/microscope.jpg" alt="recherche scientifique" class="dezoom-effect">
                        </div>
                    </div>
                </v-row>
                <v-row>
                    <div class="photo-2">
                        <img src="../images/cage_souris.jpg" alt="cage pour souris" class="dezoom-effect">
                    </div>
                </v-row>
            </v-col>
        </v-row>
        <v-row style="margin-top: 50px;">
            <v-col cols="12" sm="1" lg="2"></v-col>
             <v-col cols="12" sm="10" lg="8" >
                <div class="solution">
                    <h2 id="la-solution" class="blue-title">{{ $t('page-home.miceTrackingSolution') }} <br> </h2>
                    <p class="text-classi"></p>
                    <div class="flex-container">
                        <div class="white-bg">
                            <ul>
                                <li class="custom-list-item"><a href="/products/matrice">{{ $t('page-home.miceTrackingSolution1') }} </a> {{ $t('page-home.readingPlatforms') }}</li>
                                <li class="custom-list-item"><a href="/products/implants">{{ $t('page-home.miceTrackingSolution2') }}</a> {{ $t('page-home.subcutaneousImplant') }}</li>
                                <li class="custom-list-item"><a href="/products/actionneurs-capteurs">{{ $t('page-home.miceTrackingSolution3') }}</a>{{ $t('page-home.actuatorsSensors') }}</li>
                            </ul>
                        </div>
                        <div class="white-bg">
                            <ul>
                                <li class="custom-list-item"><a href="">MiceApp</a>{{ $t('page-home.AppDescription') }}</li>
                                <li class="custom-list-item"><a href="/logiciel/micecloud">MiceCloud</a>{{ $t('page-home.miceCloudDescription') }}</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div style="margin-top: 80px; margin-bottom: 40px;">
                            <img src="../images/schémas-solution-matrice.png" alt="schéma de la solution matrice" class="schéma-système">
                        </div>
                        <p class="text-classi">{{ $t('page-home.miceAppDescriptionPart1') }}<br><br>{{ $t('page-home.miceAppDescriptionPart2') }}<br><br>{{ $t('page-home.miceAppDescriptionPart3') }}<br><br>{{ $t('page-home.miceAppDescriptionPart4') }}</p>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="1" lg="2"></v-col>
            <v-col cols="12" sm="10" lg="8">
                <div class="video">
                    <img src="../images/schéma-global-comportement.png" class="schéma-système2" alt="schéma des mesures de la cage et de l'implant">
                </div>
            </v-col>
        </v-row>
        <banner-contact/>
        <v-row style="margin-top: 100px;" >
            <v-col cols="12" sm="1" lg="1"></v-col>
            <v-col cols="12" sm="10" lg="5">
                <div class="video-logi">
                    <iframe style="width: 100%; height: 400px;" src="https://www.youtube.com/embed/JtSLimBRV80" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </v-col>
            <v-col cols="12" sm="1" lg="1"></v-col>
            <v-col cols="12" sm="10" lg="4" >
                <div class="solution">
                    <h2 class="blue-title">{{ $t('pageMiceCloud.softwareTitle') }}</h2>
                    <p class="text-classi"> {{ $t('pageMiceCloud.softwareDescription') }}</p>
                </div>
            </v-col>
            <v-col cols="12" sm="1" lg="1"></v-col>
        </v-row>
        
        <v-row style="margin-bottom: 50px; margin-top: 100px;" class="width5">
            <v-col cols="12" xs="1" sm="1" lg="2"></v-col>
             <v-col cols="12"  xs="10" sm="10" lg="8" >
                    <h2 class="blue-title">{{ $t('page-home.applicationsExample') }} </h2>
                    <div class="list-ico-h-home">
                        <div>
                            <img src="../images/application-animalerie.jpg" alt="application en élevage" class="icon">
                            <p class="ico-caption">{{ $t('page-home.breeding') }}</p>
                        </div>
                        <div>
                            <img src="../images/cage-souris.jpg" alt="animalerie" class="icon">
                            <p class="ico-caption">{{ $t('page-home.petShop') }}</p>
                        </div>
                        <div>
                            <img src="../images/application-pharmaceutique.jpg" alt="application pharmaceutique" class="icon">
                            <p class="ico-caption">{{ $t('page-home.pharmaceutical') }}</p>
                        </div>
                        <div>
                            <img src="../images/application-medical.jpg" alt="application médicale" class="icon">
                            <p class="ico-caption">{{ $t('page-home.medical') }}</p>
                        </div>
                    </div>
            </v-col>
            <v-col cols="12" sm="1" lg="2"></v-col>
        </v-row>
        

		<language-popup></language-popup>
        <foot/>
    </div>
</template>


<style scoped>

.img{
    background-image: url(../images/solution-micetracking.jpg);
    background-size: cover;
    width: 100%;
    height: 95vh;
    background-repeat: no-repeat;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1;
}

.img h1{
    width: 80%;
    position: absolute;
    bottom: 7%;
}

.img p{
    font-family: Roboto;
    font-weight: 400;
    font-size: 17px;
    color: white;
    position: absolute;
    line-height: 20px;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animation-group {
  animation: fadeInOut 3s infinite;
  animation-delay: 0s;
}

.animation-group:nth-child(2) {
  animation-delay: 1s;
}
.animation-group:nth-child(4) {
  animation-delay: 1s;
}
.animation-group:nth-child(6) {
  animation-delay: 1s;
}
.animation-group:nth-child(8) {
  animation-delay: 1s;
}
.animation-group:nth-child(10) {
  animation-delay: 1s;
}
.animation-group:nth-child(12) {
  animation-delay: 1s;
}

.paragraph{
    padding: 4% 10%;
    margin-top: -12px;
    background-color: white;
    position: relative; 
    border-radius: 15px;
    z-index: 2;
}



.solution h1{
    text-align: left;
    margin: 20px 0px;
}

.list-ico-h-home {
    margin-top: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.list-ico-h-home div {
    text-align: center;
    position: relative;
    width: 24%;
    
}

.list-ico-h-home .ico-caption {
    margin-top: 10px;
    font-family: Roboto, medium;
    font-size: 25px;
    color: var(--bleu);
}

.list-ico-h-home .icon {
    transition: transform 0.3s ease;
    width: 100%;
    border-radius: 10px;
    height: auto;
}

.list-ico-h-home .icon:hover {
    transform: scale(1.1);
}

.photo-1 {
  overflow: hidden;
  width: 50%; /* Augmentez cette valeur si nécessaire */
  height: 45vh;
  margin-left: -20%;
  border-radius: 15px;
}

.photo-1 img {
  transform: scale(0.6); /* Ajustez cette valeur pour déterminer le niveau de zoom souhaité */
  transform-origin: top left; /* Détermine le point de référence du zoom */
  margin-left: -40%;
}

.photo-2{
    max-width: 105%;
    overflow: hidden;
    margin-top: 40px;
    margin-left: -20%;
    height: 34vh;
    border-radius: 15px;
}
.photo-2 img{
    width: 100%;
}

.photo-3{
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    margin-left: 10%;
}
.photo-3 img{
    width: 110%;
     transform: scale(1.1);
}

.photo-4{
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    margin-left: 10%;
    margin-top: 15px;
    
}
.photo-4 img{
    width: 120%;
    margin-left: -20%;
    transform: scale(1.1);
}

.dezoom-effect {
  transition: transform 0.3s ease-out;
  transform-origin: center center;
}

.dezoom-effect:hover {
  transform: scale(1.2);
}

.dezoom-effect-2 {
  transition: transform 0.3s ease-out;
}

.dezoom-effect-2:hover {
  transform: scale(0.7);
}

.schéma-système{
    width: 120%;
    margin-left: -10%;
}

.schéma-système2{
    width: 110%;
}


.flex-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.custom-list-item {
    font-family: roboto, medium;
    font-size: 16px;
    color: var(--gris);
    margin: 10px 0;
}

.custom-list-item a{
    font-family: roboto, medium;
    font-size: 18px;
    color: var(--bleu);
    margin: 10px 0;
    text-decoration: none;
    font-weight: 600;
}

.custom-list-item a:hover{
    cursor: pointer;
    font-weight: 800;
}

.white-bg {
    width: 45%;
    background-color: white;
    margin-right: 10px; /* Espacement entre les div */
    border-radius: 15px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1264px) {
  /* Styles for screens with a width less than or equal to 576 pixels */
  /* Add your CSS code specific to this breakpoint here */

.img {
    width: 100%;
    height: 41vh;
}

.img p{
    font-size: 15px;
}

.photo-1 {
    width: 45%; /* Augmentez cette valeur si nécessaire */
    margin-left: 5%;
}

.photo-2{
    width: 90%;
    margin-left: 5%;
}

.photo-3{
    width: 90%;
    margin-left: 0%;
    height: 22vh;
}
.photo-4{
    width: 90%;
    margin-left: 0%;
    height: 22vh;
}

.schéma-système{
    margin-left: 0%;
    width: 100%;
}

.flex-container {
    display: block;
    
}

.white-bg{
    width: 100%;
    margin: 20px 0%;
}

.list-ico-h-home .ico-caption{
    font-size: 12px;
}

.paragraph{
    margin: 50px;
    padding: 30px;
    width: 90%;
    margin-left: 5%;
}

.solution{
    margin: 50px;
    padding: 30px;
    width: 90%;
    margin-left: 5%;
}
.video-logi{
    margin-top: 50px;
}
}
@media (max-width: 767px) {
.animation-group{
    display: none;
    font-size: 8px;
    line-height: 12px;
}
.photo-1{
    height: 41vh;
    overflow: hidden;
}

.photo-1 img{
    margin-left: -80%;
}
.img {
    margin-top: 72px;
    width: 100%;
    height: 23vh;
}

.img h1{
    font-size: 23px;
    width: 100%;
}
}

</style>

<script>
import foot from '@/components/Footer.vue'
import navbar from '@/components/NavBarV2.vue'
import LanguagePopup from '../components/LanguagePopup.vue';
import bannerContact from '../components/banner-contact.vue';
export default {
    metaInfo: {
        title: 'MiceTracking - Système de suivi et de bien-être des souris de laboratoire',
        meta: [
            {
                name: 'description',
                content: 'Découvrez le système MiceTracking pour le suivi de la santé et du bien-être des souris de laboratoire, avec des implants sous-cutanés et une panoplie de capteurs et actionneurs.'
            },
            {
                name: 'keywords',
                content: 'MiceTracking, suivi de la santé des souris de laboratoire, implants sous-cutanés, capteurs environnementaux, bien-être animal'
            }
        ]
    },
    components:{
        foot,
        navbar,
        LanguagePopup,
        bannerContact
    },
}
</script>